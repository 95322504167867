import { EnvEnsure } from '@lp-lib/env-ensure';

import { type ClientEnv } from '../../app/env.both';

const cache = new Set<string>();
function warnOnce(...args: unknown[]) {
  if (process.env.NODE_ENV === 'test') return;
  if (cache.has(args[0] as string)) return;
  cache.add(args[0] as string);
  console.warn(...args);
}

export function getEnsure(env: ClientEnv) {
  const envEnsure = new EnvEnsure(warnOnce, env);
  return envEnsure.ensure.bind(envEnsure);
}
