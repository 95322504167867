export class EnvEnsure {
  constructor(
    private warn: (msg: string) => void,
    private env: Record<string, unknown> = process.env
  ) {}

  ensure<T extends string>(
    key: string,
    defaultVal?: string,
    validator?: (val: string) => boolean
  ): T {
    if (!(key in this.env)) {
      if (!defaultVal) {
        if (this.env['NODE_ENV'] === 'production')
          throw new Error(`Required key ${key} not found in ENV!`);
        else
          this.warn(
            `[config] production-required key ${key} not present in ENV`
          );
        return '' as T;
      }
      this.warn(`[config] using default value: ${key}=${defaultVal}`);
      return defaultVal as T;
    }

    const value = this.env[key];
    if (
      !value ||
      (typeof value === 'string' && validator && validator(value) === false)
    )
      throw new Error(`Required key ${key} was empty or falsy in ENV!`);
    return value as T;
  }
}

export class EnvEnsure2 {
  constructor(private warn: (msg: string) => void) {}

  throwIfMissing<T extends string, K extends string = string, Obj = unknown>(
    obj: Obj,
    key: K
  ): T {
    const value = (obj as { [Keys in K]: T })[key];
    if (!value)
      throw new Error(
        `Required ${key} was empty or not present. Value: ${value}`
      );
    return value;
  }

  throwIfMissingAnd<T extends string, K extends string = string, Obj = unknown>(
    obj: Obj,
    key: K,
    condition: () => boolean
  ): T {
    const value = (obj as { [Keys in K]: T })[key];
    if (!value && condition())
      throw new Error(
        `Required ${key} was empty or not present. Value: ${value}`
      );
    return value;
  }

  warnIfMissing<T extends string, K extends string, Obj>(obj: Obj, key: K): T {
    const value = (obj as { [Keys in K]: T })[key];
    if (!value)
      this.warn(`[config] ${key} was empty or not present. Value: ${value}`);
    return value;
  }

  warnIfMissingAnd<T extends string, K extends string, Obj>(
    obj: Obj,
    key: K,
    condition: () => boolean
  ): T {
    const value = (obj as { [Keys in K]: T })[key];
    if (!value && condition())
      this.warn(`[config] ${key} was empty or not present. Value: ${value}`);
    return value;
  }
}
