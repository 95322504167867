import { type ClientEnv } from '../../app/env.both';
import { type ServerEnv } from '../../app/env.server';
import { isServer } from '../utils/isServer';

export function getEnv(): ClientEnv {
  return isServer() ? getServerEnv() : { ...import.meta.env, ...window.ENV };
}

export function getServerEnv(): ServerEnv | null {
  return isServer()
    ? ({ ...import.meta.env, ...process.env } as unknown as ServerEnv)
    : null;
}
