import { type ClientEnv } from '../../app/env.both';
import { getEnsure } from './ensure';

export type SentryConfig = ReturnType<typeof sentry>;

export function sentry(env: ClientEnv) {
  const ensure = getEnsure(env);
  return {
    dsn: ensure('SENTRY_DSN', ''),
    tracesSampleRate: Number(env.SENTRY_SAMPLE_RATE || 1.0),
    buildSHA: ensure('BUILD_SHA', 'localdev'),
    organization: ensure('SENTRY_API_ORGANIZATION', ''),
  };
}
